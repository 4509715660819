<template>
  <div id="app">
    <el-container>
      <el-header>
        <LYHeader></LYHeader>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <el-footer height="auto">
        <LYFooter></LYFooter>
      </el-footer>
    </el-container>
    <el-backtop target="#app">
      <div class="backtop-content">
        <div class="img-wrapper">
          <img src="./assets/images/totop.png" alt="" />
        </div>
        置顶
      </div>
    </el-backtop>
  </div>
</template>

<script>
import LYHeader from "./components/LYHeader";
import LYFooter from "./components/LYFooter";
export default {
  name: "app",
  components: { LYHeader, LYFooter }
};
</script>

<style lang="scss">
#app {
  height: 100vh;
  overflow-y: scroll;
  .el-backtop {
    width: 70px;
    height: 70px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 14px;
    color: #fff;
    z-index: 100;
  }
}
.el-container {
  .el-header {
    background-color: #fff;
    padding: 0;
    color: #333;
    text-align: center;
    line-height: 60px;
    z-index: 1000;
    height: 80px !important;
  }
  .el-footer {
    padding: 0;
    color: #fff;
    background: #383d4a;
  }
  .el-main {
    overflow-y: hidden;
    padding: 0;
    color: #333;
    text-align: center;
    background-color: #fff;
  }
}

.el-dropdown-menu {
  width: 200px;
  border-radius: 10px !important;
  .el-dropdown-menu__item {
    font-size: 18px;
    text-align: center;
    padding: 5px 0;
    &:hover {
      /* color: rgb(0, 123, 185) !important; */
      /* background-color: rgb(0, 123, 185) !important; */
    }
  }
}

.el-carousel .el-carousel__container {
  height: 100%;
  max-height: 570px;
}

.bg-img {
  height: 30vw;
  max-height: 520px;
  min-height: 100px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
  }
}
</style>
