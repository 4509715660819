<template>
  <div class="ly-footer">
    <div class="footer-top-wrapper">
      <div class="footer-top wrap-v1">
        <div class="block">
          <div class="logo">
            <img src="../assets/images/footer-logo.png" alt="" />
          </div>
          <div class="desc">
            蓝亚（深圳）信息技术有限公司
          </div>
        </div>
        <div class="block tl">
          <p>
            <img src="../assets/icon/u29.svg" alt="" />
            <span>售前热线：18026933971</span>
            <span>售后热线：18026933971</span>
          </p>
          <p>
            <img src="../assets/icon/u31.svg" alt="" />
            <span>邮箱：bainfo@mail.blueasiainfo.com</span>
          </p>
          <p>
            <img src="../assets/icon/u34.svg" alt="" />
            <span>广东省深圳市宝安区3区龙井2路3号中粮大厦905（宝安）</span>
          </p>
          <p>
            <img src="../assets/icon/u34.svg" alt="" />
            <span>深圳市南山区桃源街道平山社区平山一路2号南山云谷创业园二期9栋1层A（南山）</span>
          </p>
        </div>
        <div class="block">
          <img src="../assets/images/u40.png" alt="" class="ewm" />
          <div class="desc">微信公众号</div>
          <!-- <p class="title">蓝亚（深圳）信息技术有限公司</p>
      <p class="address">
        地址：中国(辽宁)自由贸易试验区大连经济技术开发区金窑路38-7-1号1层
      </p>
      <p class="tel">电话：400-556-5652</p> -->
        </div>
      </div>
    </div>

    <div class="footer-bottom">
      <div class="footer-list">
        <a href="#/">
          <div class="footer-item">关于蓝亚</div>
        </a>
        <div class="footer-line">|</div>
        <a href="#/">
          <div class="footer-item">支持与服务</div>
        </a>
        <div class="footer-line">|</div>
        <a href="#/">
          <div class="footer-item">能力工具</div>
        </a>
        <div class="footer-line">|</div>
        <a href="#/">
          <div class="footer-item">热门产品</div>
        </a>
        <div class="footer-line">|</div>
        <a href="#/">
          <div class="footer-item">法律声明</div>
        </a>
      </div>
      <div class="address">
        蓝亚（深圳）信息技术有限公司
        <a href="https://beian.miit.gov.cn">粤ICP备17165058号</a>
      </div>
      <!-- <div class="copyright">
        声明：本平台仅提供技术支持（广告展示和引流服务），所有银行保函产品均由保函下
        发银行提供，所有保险产品的销售、理赔等服务均由与本平台合作的永安保险代理公司
        提供。
      </div> -->
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.ly-footer {
  text-align: center;

  .footer-top-wrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    .footer-top {
      padding-top: 80px;
      padding-bottom: 50px;
      font-size: 14px;
      line-height: 28px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .block {
        &.tl {
          text-align: left;
        }
      }
      .logo {
        font-size: 30px;
        font-weight: 600;
        color: #ffffff;
        line-height: 42px;
        img {
          height: 115px;
        }
      }
      .desc {
        margin-top: 20px;
      }
      .ewm {
        width: 115px;
        height: 115px;
      }
      p {
        margin: 10px 0;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
        span {
          margin-left: 20px;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .footer-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    .footer-list {
      color: #fff;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        color: #fff;
        &:hover {
          text-decoration: none;
        }
      }
      .footer-line {
        margin: 0 10px;
      }
    }
    .address {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.4);
      line-height: 20px;
      /* margin-bottom: 5px; */
      a {
        color: rgba(255, 255, 255, 0.4);
      }
    }
    .copyright {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.4);
      line-height: 20px;
    }
  }
}
</style>
