<template>
  <div class="ly-header wrap-v1">
    <div class="logo">
      <router-link to="/">
        <img src="../assets/images/header-logo2.png"
      /></router-link>
    </div>
    <el-menu
      active-text-color="#007BB9"
      mode="horizontal"
      router
      :default-active="activeIndex"
      @select="handleSelect"
    >
      <!-- 首页 -->
      <el-menu-item index="/">
        {{ $t("nav.home") }}
      </el-menu-item>
      <!-- 动态资讯 -->
      <el-menu-item index="/news"> {{ $t("nav.news") }} </el-menu-item>
      <!-- 产品展示 -->
      <el-menu-item index="/product">
        <template slot="title">
          <el-dropdown placement="bottom">
            <span> {{ $t("nav.proDisplay") }} </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <router-link to="/product/hotProducts" tag="div">
                  {{ $t("nav.proDetails1") }}
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/product/smartUnion" tag="div">
                  {{ $t("nav.proDetails2") }}
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/product/dataTrend" tag="div">
                  {{ $t("nav.proDetails3") }}
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/product/containerCloud" tag="div">
                  {{ $t("nav.proDetails4") }}
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/product/artificialIntelligence" tag="div">
                  {{ $t("nav.proDetails5") }}
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/product/industryApplication" tag="div">
                  {{ $t("nav.proDetails6") }}
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/product/contentProduction" tag="div">
                  {{ $t("nav.proDetails7") }}
                </router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-menu-item>
      <!-- 解决方案 -->
      <el-menu-item index="/solution" disabled>
        <template slot="title">
          <el-dropdown placement="bottom">
            <span> {{ $t("nav.solution") }} </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <router-link to="/solution/applicationPlatform" tag="div">
                  {{ $t("nav.solution1") }}
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/solution/dataPlatform" tag="div">
                  {{ $t("nav.solution2") }}
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/solution/videoService" tag="div">
                  {{ $t("nav.solution3") }}
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/solution/securityService" tag="div">
                  {{ $t("nav.solution4") }}
                </router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-menu-item>
      <!-- 关于我们 -->
      <el-menu-item index="/about"> {{ $t("nav.about") }} </el-menu-item>
      <!-- 联系我们 -->
      <el-menu-item index="/contact"> {{ $t("nav.contact") }} </el-menu-item>
      <!-- 加入蓝亚 -->
      <el-menu-item index="/joinUs"> {{ $t("nav.join") }} </el-menu-item>
      <!-- 切换语言 -->
      <el-menu-item disabled index="/chang-lange">
        <template slot="title">
          <el-dropdown placement="bottom" @command="changeLang">
            <el-button round>{{ $t("nav.lang") }}</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="zh-CN">中文</el-dropdown-item>
              <el-dropdown-item command="en">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: "/"
    };
  },
  watch: {
    $route(newVal, oldVal) {
      // console.log("newVal", newVal, oldVal);
      if (!oldVal.name) {
        this.activeIndex = newVal.path;
      }
      if (newVal.path.indexOf("/product") !== -1) {
        this.activeIndex = "/product";
        return;
      }
      if (newVal.path.indexOf("/solution") !== -1) {
        this.activeIndex = "/solution";
        return;
      }
      // 职位详情
      if (newVal.path === "/positionDetails") {
        this.activeIndex = "/joinUs";
        return;
      }
      this.activeIndex = newVal.path;
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log("handleSelect", key, keyPath, this.activeIndex);
      // if (key !== "/solution") this.$router.push(key);
      // if (key) {
      //   this.activeIndex = key;
      // }
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
      this.$store.commit("changeLang", lang);
    }
  }
};
</script>
<style scoped lang="scss">
.ly-header {
  .el-button.is-round {
    padding: 7px 14px;
  }
  .logo {
    img {
      width: 284px;
      height: 70px;
    }
  }
  height: 100%;
  line-height: 83px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1200px) {
    .el-menu-item,
    .el-dropdown {
      padding: 0 10px !important;
    }
  }
  @media screen and (max-width: 1150px) {
    .el-menu-item,
    .el-dropdown {
      font-size: 14px !important;
    }
  }
  @media screen and (max-width: 1100px) {
    .el-menu.el-menu--horizontal {
      display: flex;
      overflow: hidden;
    }
    .el-menu-item {
      padding: 0 10px !important;
    }
  }

  .el-menu {
    height: 100%;
    .el-menu-item {
      line-height: 83px;
      height: 100%;
      padding: 0 15px;
      font-size: 16px;
      &.is-disabled {
        opacity: 1;
        cursor: pointer;
      }
      &.is-active {
        border-bottom: 4px solid #409eff;
      }
      .el-dropdown {
        font-size: 16px;
        color: inherit;
        span {
          display: block;
          height: 100%;
        }
      }
    }
  }
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
}
</style>
