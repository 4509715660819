export default {
  nav: {
    home: "首页",
    news: "动态资讯",
    proDisplay: "产品展示",
    proDetails1: "热门产品",
    proDetails2: "智能物联",
    proDetails3: "数据趋势",
    proDetails4: "容器云",
    proDetails5: "人工智能",
    proDetails6: "行业应用",
    proDetails7: "内容制作",
    solution: "解决方案",
    solution1: "应用平台",
    solution2: "数据平台",
    solution3: "视频服务",
    solution4: "安全服务",
    about: "关于我们",
    contact: "联系我们",
    join: "加入蓝亚",
    lang: "切换语言"
  }
};
