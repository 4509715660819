import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // 首页
  {
    path: "/",
    name: "home",
    component: () => import("../views/home.vue")
  },
  // 关于我们
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about.vue")
  },
  // 合作案例 已经废弃不展示
  // {
  //   path: "/case",
  //   name: "case",
  //   component: () => import("../views/case.vue")
  // },
  // 新闻列表页
  {
    path: "/news",
    name: "news",
    component: () => import("../views/news")
  },
  // 新闻详情
  {
    path: "/news/details/:id",
    name: "newsDetails",
    component: () => import("../views/news/details.vue")
  },
  // 产品展示 目前废弃不展示
  // {
  //   path: "/product-display",
  //   name: "ProductDisplay",
  //   component: () => import("../views/ProductDisplay.vue")
  // },
  // 产品展示 -> 热门产品
  {
    path: "/product",
    redirect: "/product/hotProducts"
  },
  {
    path: "/product/hotProducts",
    name: "hotProducts",
    component: () => import("../views/product/hotProducts.vue")
  },
  // 产品展示 -> 智能物联
  {
    path: "/product/smartUnion",
    name: "smartUnion",
    component: () => import("../views/product/smartUnion.vue")
  },
  // 产品展示 -> 数据趋势
  {
    path: "/product/dataTrend",
    name: "dataTrend",
    component: () => import("../views/product/dataTrend.vue")
  },
  // 产品展示 -> 容器云
  {
    path: "/product/containerCloud",
    name: "containerCloud",
    component: () => import("../views/product/containerCloud.vue")
  },
  // 产品展示 -> 人工智能
  {
    path: "/product/artificialIntelligence",
    name: "artificialIntelligence",
    component: () => import("../views/product/artificialIntelligence.vue")
  },
  // 产品展示 -> 行业应用
  {
    path: "/product/industryApplication",
    name: "industryApplication",
    component: () => import("../views/product/industryApplication.vue")
  },
  // 产品展示 -> 内容制作
  {
    path: "/product/contentProduction",
    name: "contentProduction",
    component: () => import("../views/product/contentProduction.vue")
  },
  // 产品展示 -> 某一个小分类  -> 内容详情
  {
    path: "/product/details/:id",
    name: "productDetails",
    component: () => import("../views/product/details.vue")
  },
  // 解决方案 目前废弃不展示
  // {
  //   path: "/solution",
  //   name: "Solution",
  //   component: () => import("../views/Solution.vue")
  // },
  // 解决方案 -> 应用平台
  {
    path: "/solution/applicationPlatform",
    name: "applicationPlatform",
    component: () => import("../views/solution/applicationPlatform.vue")
  },
  // 解决方案 -> 数据平台
  {
    path: "/solution/dataPlatform",
    name: "dataPlatform",
    component: () => import("../views/solution/dataPlatform.vue")
  },
  // 解决方案 -> 视频服务
  {
    path: "/solution/videoService",
    name: "videoService",
    component: () => import("../views/solution/videoService.vue")
  },
  // 解决方案 -> 安全服务
  {
    path: "/solution/securityService",
    name: "securityService",
    component: () => import("../views/solution/securityService.vue")
  },
  // 解决方案 -> 某个子栏目 -> 服务内容的详情
  {
    path: "/solution/details/:id",
    name: "solutionDetails",
    component: () => import("../views/solution/details.vue")
  },
  // 加入我们
  {
    path: "/joinUs",
    name: "joinUs",
    component: () => import("../views/joinUs.vue")
  },
  // 联系我们
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/contact.vue")
  },
  // 职位详情
  {
    path: "/positionDetails",
    name: "positionDetails",
    component: () => import("../views/positionDetails.vue")
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

// 解决两次点击同名路由会报警告的错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

export default router;
