import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let lang = localStorage.getItem("ly-lang");
lang = lang ? lang : "zh-CN";

const store = new Vuex.Store({
  state: {
    lang: lang
  },
  mutations: {
    changeLang(state, lang) {
      state.lang = lang;
    }
  },
  actions: {},
  modules: {}
});

store.subscribe((mutation, state) => {
  // console.log(mutation.type);
  // console.log(state);
  if (mutation.type === "changeLang") {
    localStorage.setItem("ly-lang", state.lang);
  }
});

export default store;
