export default {
  nav: {
    home: "HOME",
    news: "NEWS",
    proDisplay: "PRODUCTS",
    proDetails1: "Hot products",
    proDetails2: "Smart union",
    proDetails3: "Data trend",
    proDetails4: "Container cloud",
    proDetails5: "Artificial intelligence",
    proDetails6: "Industry application",
    solution: "SOLUTION",
    solution1: "Application platform",
    solution2: "Data platform",
    solution3: "Video service",
    solution4: "Security service",
    about: "ABOUT US",
    contact: "CONTACT US",
    join: "JOIN US",
    lang: "Language"
  }
};
