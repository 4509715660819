import Vue from "vue";
import {
  Header,
  Main,
  Footer,
  Container,
  Menu,
  MenuItem,
  Submenu,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Button,
  Backtop,
  Icon,
  Carousel,
  CarouselItem,
  Image,
  Form,
  FormItem,
  Option,
  Select,
  Input,
  Table,
  TableColumn,
  Loading,
  Pagination,
  Breadcrumb,
  BreadcrumbItem
} from "element-ui";

Vue.use(BreadcrumbItem);
Vue.use(Breadcrumb);
Vue.use(Header);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Container);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Button);
Vue.use(Backtop);
Vue.use(Icon);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Image);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Option);
Vue.use(Select);
Vue.use(Input);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Loading);
Vue.use(Pagination);
